import { USER_ROLES, USER_ROLES_OPTIONS } from "../../common/constants";

export const convertEmployeeToApi = (employee) => ({
  ...employee,
  email: employee.email.trim(),
  name: employee.name.trim(),
  lastName: employee.lastName.trim(),
  role: employee.role.value,
  password: employee.passwords?.password,
  warehouseId: employee.warehouse?.id,
  cityId: employee?.cityId,
});

export const convertEmployeeFromApi = (employeeDetails) => ({
  ...employeeDetails,
  warehouse: {
    ...employeeDetails.warehouse,
    name: `${employeeDetails.warehouse?.city ?? ""}`,
  },
  role: USER_ROLES_OPTIONS.find((userRole) => userRole.value === employeeDetails.role),
});
